import Card from './primitives/Card';
import Cards from './primitives/Cards';
import Container from './primitives/Container';
import Header from './primitives/Header';
import Link from './primitives/Link';
import MediaServerImage from 'components/MediaServerImage';
import { Card as CardType, State as Props } from 'state/Promo/types';
import { fit } from 'utils/mediaServerImageMaker/opsString';
import { get } from 'lodash-es';
import { PureComponent } from 'react';

class PromoCards extends PureComponent<
  Omit<Props, 'source'> & { source: string }
> {
  cardClick = (card: CardType) => {
    const { source } = this.props;
    const cardPath = get(card, ['link', 'urls', 'web']);
    const subscriptionCard = cardPath.split('?subscriptionId=')[1];

    if (source === 'ROKU' || source === 'APPLE' || source === 'GOOGLE') {
      if (
        (subscriptionCard && subscriptionCard.includes('FAMILY')) ||
        (subscriptionCard && subscriptionCard.includes('ANNUAL'))
      ) {
        window.open('/family-validation/');
      } else {
        window.open(cardPath);
      }
    } else {
      window.open(cardPath);
    }
  };

  render() {
    const { cards } = this.props;

    return cards && cards.length ? (
      <Container>
        <Header>Current Offers</Header>
        <Cards>
          {cards.map(card => (
            <Card key={card.id}>
              <MediaServerImage
                alt={card.title}
                aspectRatio={52 / 33}
                ops={[fit(312, 198)]}
                src={card.img_uri}
              />
              <Link onClick={() => this.cardClick(card)}>
                {get(card, ['link', 'name'])}
              </Link>
              <p>{card.subtitle}</p>
            </Card>
          ))}
        </Cards>
      </Container>
    ) : null;
  }
}

export default PromoCards;
